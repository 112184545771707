import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { request } from "utils/api";
import { BackOfficeUserResponse, PostBackOfficeUser } from "types/common";
import { useDebouncedValue } from "common/hooks/useDebouncedValue";
import DeleteUserConfirmation from "./components/DeleteUserConfirmation";
import EditUsersRoleModal from "./components/EditUsersRoleModal";
import CreateNewUserModal from "./components/CreateNewUserModal";
import { usePermission } from "common/hooks/usePermission";

const UsersList: React.FC = (): React.ReactElement => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCreateUserModalOpen,
    onOpen: onCreateUserModalOpen,
    onClose: onCreateUserModalClose,
  } = useDisclosure();
  const isAlowed = usePermission();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const [userId, setUserId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [roleId, setRoleId] = useState<number>();
  const delayedSearchUser = useDebouncedValue(
    searchParams.get("user_name") || ""
  );
  const rowHoverBg = useColorModeValue("secondaryGray.300", "navy.900");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColorButton = useColorModeValue("#8500fa", "white");
  const buttonTextColor = useColorModeValue("white", "black");
  const updateQueryParams = (key: string, value: string): void => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };
  const { data: usersData, isLoading } = useQuery({
    queryKey: ["office_users", delayedSearchUser],
    queryFn: async () => {
      const response = await request<BackOfficeUserResponse>(
        `/office_users?user_name=${delayedSearchUser}`
      );
      return response;
    },
  });
  const users = usersData?.success ? usersData?.data.items : [];

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({
      data,
      type,
    }: {
      data?: PostBackOfficeUser;
      type: string;
    }) => {
      return type === "DELETE"
        ? request<BackOfficeUserResponse>(`/office_users/${userId}/delete`, {
            headers: { "Content-Type": "application/json" },
            method: "DELETE",
          })
        : type === "POST"
          ? request(`/office_users`, {
              headers: { "Content-Type": "application/json" },
              method: "POST",
              body: JSON.stringify(data),
            })
          : request<BackOfficeUserResponse>(`/office_users/${userId}`, {
              headers: { "Content-Type": "application/json" },
              method: "PUT",
              body: JSON.stringify(data),
            });
    },
    onSuccess: (data, { type }) => {
      if (data.code === 0) {
        const message =
          type === "DELETE"
            ? "Account deleted successfully."
            : type === "POST"
              ? "User created successfully."
              : "User updated successfully.";
        queryClient.invalidateQueries({
          queryKey: ["office_users", delayedSearchUser],
        });
        onClose();
        onEditModalClose();
        onCreateUserModalClose();
        toast({
          position: "top",
          title: message,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else if (data.code === 409 && type === "POST") {
        toast({
          position: "top",
          title: "A user already exist with the same email",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Flex flexDir="column">
      <Flex w="100%" alignItems="center" gap={20}>
        <SearchBar
          value={searchParams.get("user_name") || ""}
          placeholder="user"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            updateQueryParams("user_name", e.target.value);
          }}
          flex={1}
          my={4}
          borderRadius="30px"
        />
        {isAlowed("users", "create") && (
          <Button
            _hover={{}}
            onClick={onCreateUserModalOpen}
            textColor={buttonTextColor}
            bgColor={bgColorButton}
            px={10}
          >
            Create New User
          </Button>
        )}
      </Flex>
      <Table variant="simple" color={textColor} mb="24px" mt="12px">
        <Thead>
          <Tr>
            <Th colSpan={1} pe="10px" borderColor="black">
              <Flex
                justifyContent="space-between"
                align="center"
                minW="130px"
                fontSize="12px"
                color={textColor}
              >
                NAME
              </Flex>
            </Th>
            <Th colSpan={1} pe="10px" borderColor="black">
              <Flex
                justifyContent="space-between"
                align="center"
                minW="130px"
                fontSize="12px"
                color={textColor}
              >
                EMAIL
              </Flex>
            </Th>
            <Th colSpan={1} pe="10px" borderColor="black">
              <Flex
                justifyContent="space-between"
                align="center"
                minW="130px"
                fontSize="12px"
                color={textColor}
              >
                ROLE
              </Flex>
            </Th>
            {isAlowed("users", "edit") && isAlowed("users", "delete") && (
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  ACTIONS
                </Flex>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody h="100%">
          {isLoading ? (
            <>
              {[...Array(4)].map((_, index) => (
                <Tr key={index}>
                  <Td colSpan={5}>
                    <Skeleton height="35px" />
                  </Td>
                </Tr>
              ))}
            </>
          ) : (
            <>
              {users?.map((itm, index) => {
                return (
                  <Tr key={index}>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.name ? itm.name : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.email ? itm.email : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.roles[0]?.name ? itm?.roles[0]?.name : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      <Flex gap={2}>
                        {isAlowed("users", "delete") && (
                          <Box
                            w="fit-content"
                            rounded="20px"
                            onClick={() => {
                              setUserId(users[index].id);
                              onOpen();
                            }}
                            p={2}
                            _hover={{ bg: rowHoverBg, cursor: "pointer" }}
                          >
                            <MdDelete fill="red" size="25px" />
                          </Box>
                        )}
                        {isAlowed("users", "edit") && (
                          <Box
                            onClick={() => {
                              setUserId(users[index].id);
                              setRoleId(users[index]?.roles[0]?.id);
                              onEditModalOpen();
                            }}
                            w="fit-content"
                            rounded="20px"
                            p={2}
                            _hover={{ bg: rowHoverBg, cursor: "pointer" }}
                          >
                            <MdOutlineModeEdit size="25px" fill="yellow" />
                          </Box>
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                );
              })}
            </>
          )}
        </Tbody>
      </Table>
      {isOpen && (
        <DeleteUserConfirmation
          onClose={onClose}
          isOpen={isOpen}
          mutate={mutate}
        />
      )}
      {isEditModalOpen && (
        <EditUsersRoleModal
          userId={userId}
          roleId={roleId}
          onClose={onEditModalClose}
          mutate={mutate}
          isOpen={isEditModalOpen}
        />
      )}
      {isCreateUserModalOpen && (
        <CreateNewUserModal
          mutate={mutate}
          isOpen={isCreateUserModalOpen}
          onClose={onCreateUserModalClose}
        />
      )}
    </Flex>
  );
};

export default UsersList;
