import { IoEyeSharp } from "react-icons/io5";
import {
  Button,
  Flex,
  Select,
  useColorModeValue,
  Text,
  Box,
  Divider,
  AbsoluteCenter,
  useDisclosure,
  Checkbox,
  useToast,
  Tooltip,
  Grid,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import {
  BackOfficePermissionsResponse,
  BackOfficeRolesResponse,
} from "types/common";
import { request } from "utils/api";
import { MdEdit, MdDelete, MdAdd } from "react-icons/md";
import CreateNewRoleModal from "./components/CreateNewRoleModal";

interface ModulePermissions {
  [action: string]: number;
}

const moduleNamesTranslator: { [key: string]: string } = {
  dashboard: "Dashboard",
  reports: "Reports",
  pages: "Pages",
  users: "Users",
  products: "Products",
  purchases: "Purchases",
  "request-types": "Request types",
};

interface GroupedPermissions {
  [module: string]: ModulePermissions;
}
interface PermissionType {
  name: string;
  label: string;
  icon: React.ReactElement;
  tooltipBg: string;
  value: boolean;
}

const RolesAndPermissions: React.FC = (): React.ReactElement => {
  const toast = useToast();
  const [rolePermissions, setRolePermissions] = useState<number[]>([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const buttonTextColor = useColorModeValue("white", "black");
  const bgColorButton = useColorModeValue("#8500fa", "white");
  const iconColor = useColorModeValue("#8500fa", "white");
  const iconBgColor = useColorModeValue("#DEDFE7", "#a7a6ba");

  const availablePermissions: PermissionType[] = useMemo(
    () => [
      {
        name: "read",
        label: "View",
        icon: <IoEyeSharp fill={iconColor} />,
        tooltipBg: "#51a2df",
        value: false,
      },
      {
        name: "create",
        label: "Create",
        icon: <MdAdd fill={iconColor} />,
        tooltipBg: "#8dc731",
        value: false,
      },
      {
        name: "edit",
        label: "Edit",
        icon: <MdEdit fill={iconColor} />,
        tooltipBg: "#0c14e5",
        value: false,
      },
      {
        name: "delete",
        label: "Delete",
        icon: <MdDelete fill={iconColor} />,
        tooltipBg: "#cc2a2a",
        value: false,
      },
    ],
    [iconColor]
  );
  const { data: rolePermissionsData, isSuccess: isRolePermissionsSucces } =
    useQuery({
      queryKey: ["role-permissions", selectedRole],
      queryFn: async () => {
        const response = await request<BackOfficePermissionsResponse>(
          `/roles/${selectedRole}/permissions`
        );
        return response;
      },
      enabled: !!selectedRole,
    });

  const rolePerms =
    rolePermissionsData?.success && rolePermissionsData?.data.items;

  useEffect(() => {
    if (selectedRole === "") {
      setRolePermissions([]);
      return;
    }

    if (isRolePermissionsSucces) {
      setRolePermissions(rolePerms?.map((permission) => permission.id));
    }
  }, [selectedRole, isRolePermissionsSucces, rolePerms]);

  const { data: rolesData } = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await request<BackOfficeRolesResponse>(`/roles`);
      return response;
    },
  });

  const { data: permissionsData } = useQuery({
    queryKey: ["permissions"],
    queryFn: async () => {
      const response =
        await request<BackOfficePermissionsResponse>(`/permissions`);
      return response;
    },
  });

  const roles = rolesData?.success ? rolesData.data.items : [];
  const permissions = permissionsData?.success && permissionsData.data.items;
  const groupedPermissions: GroupedPermissions = {};

  permissions?.forEach((permission) => {
    const [module, action] = permission.name.split(".");
    if (!groupedPermissions[module]) {
      groupedPermissions[module] = {};
    }
    groupedPermissions[module][action] = permission.id;
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: (newRole: string) => {
      return request<{ value: string }>(`/roles`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({ role: newRole }),
      });
    },
    onSuccess: (data) => {
      if (data.code === 0) {
        queryClient.invalidateQueries({
          queryKey: ["roles"],
        });
        onClose();
        toast({
          position: "top",
          title: "The role created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        const newRole = data.success && data.data.value;
        setSelectedRole(newRole);
      } else if (data.code === 409) {
        toast({
          position: "top",
          title: "The role already exist",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    },
  });

  const { mutate: PermissionsMutate } = useMutation({
    mutationFn: () => {
      return request(`/roles/${selectedRole}/permissions`, {
        method: "PUT",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ permissions: rolePermissions }),
      });
    },
    onSuccess: (data) => {
      if (data.success) {
        setIsSubmitting(false);
        queryClient.invalidateQueries({
          queryKey: ["role-permissions", selectedRole],
        });
        toast({
          position: "top",
          title: "Role's permissions successfully updated",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    },
  });

  const handleFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    setIsSubmitting(true);
    e.preventDefault();
    PermissionsMutate();
  };

  return (
    <Flex flexDir="column" flexGrow={1} h="100%">
      <Flex justifyContent="space-between" alignItems="end" py={4}>
        <Box w="40%">
          <Text py={2}>Users Roles</Text>
          <Select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <option value="">Select a role</option>
            {roles.map((itm, index) => (
              <option key={index} value={itm.name}>
                {itm.name}
              </option>
            ))}
          </Select>
        </Box>

        <Button
          _hover={{}}
          onClick={onOpen}
          textColor={buttonTextColor}
          bgColor={bgColorButton}
          px={10}
        >
          Create New Role
        </Button>
      </Flex>
      <Box position="relative" mt={10}>
        <Divider />
        <AbsoluteCenter px="4">Permissions</AbsoluteCenter>
      </Box>
      <form
        onSubmit={handleFormSubmit}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          templateColumns="repeat(auto-fill, minmax(270px, 1fr))"
          gap={10}
          pt={20}
        >
          {Object.keys(groupedPermissions).map((module, index) => (
            <Flex
              key={index}
              justifyContent="space-between"
              alignItems="center"
              justifyItems="center"
              flex={1}
              border="2px"
              p={5}
            >
              <Flex gap={4}>
                <Checkbox
                  _checked={{
                    "& .chakra-checkbox__control": {
                      background: bgColorButton,
                      borderColor: "transparent",
                    },
                  }}
                  variant=""
                  onChange={(value) => {
                    if (value.target.checked) {
                      setRolePermissions((prev) => [
                        ...prev,
                        ...Object.values(groupedPermissions[module]),
                      ]);
                    } else {
                      setRolePermissions((prev) =>
                        prev.filter(
                          (number) =>
                            !Object.values(groupedPermissions[module]).includes(
                              number
                            )
                        )
                      );
                    }
                  }}
                  isChecked={Object.values(groupedPermissions[module]).every(
                    (permissionId) => rolePermissions.includes(permissionId)
                  )}
                />
                <Text>{moduleNamesTranslator[module]}</Text>
              </Flex>
              <Flex gap={5} alignItems="center">
                {Object.keys(groupedPermissions[module]).map(
                  (action, index) => (
                    <React.Fragment key={index}>
                      {availablePermissions.map(
                        (itm, index): React.ReactElement => {
                          if (itm.name === action)
                            return (
                              <Tooltip
                                hasArrow
                                aria-label="tooltip-icon"
                                key={index}
                                label={itm.label}
                              >
                                <Box>
                                  <Checkbox
                                    borderColor="transparent"
                                    size="xl"
                                    borderRadius="full"
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: iconBgColor,
                                      },
                                    }}
                                    key={index}
                                    icon={itm.icon}
                                    onChange={(value) => {
                                      if (value.target.checked) {
                                        setRolePermissions((prev) => [
                                          ...prev,
                                          groupedPermissions[module][action],
                                        ]);
                                      } else {
                                        setRolePermissions((prev) => {
                                          if (action === "read") {
                                            return prev.filter(
                                              (number) =>
                                                !Object.values(
                                                  groupedPermissions[module]
                                                ).includes(number)
                                            );
                                          } else {
                                            return prev.filter(
                                              (number) =>
                                                number !==
                                                groupedPermissions[module][
                                                  action
                                                ]
                                            );
                                          }
                                        });
                                      }
                                    }}
                                    _disabled={{
                                      "& .chakra-checkbox__control": {
                                        background: "transparent",
                                      },
                                      cursor: "not-allowed",
                                    }}
                                    isDisabled={
                                      action !== "read" &&
                                      !rolePermissions.includes(
                                        groupedPermissions[module]["read"]
                                      )
                                    }
                                    isChecked={rolePermissions?.includes(
                                      groupedPermissions[module][action]
                                    )}
                                    colorScheme=""
                                  />
                                </Box>
                              </Tooltip>
                            );
                        }
                      )}
                    </React.Fragment>
                  )
                )}
              </Flex>
            </Flex>
          ))}
        </Grid>
        <Flex justifyContent="end" alignItems="end" flex={1} py={10}>
          <Button
            isDisabled={!selectedRole}
            _hover={{}}
            isLoading={isSubmitting}
            textColor={buttonTextColor}
            bgColor={bgColorButton}
            px={10}
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </form>
      {isOpen && (
        <CreateNewRoleModal isOpen={isOpen} onClose={onClose} mutate={mutate} />
      )}
    </Flex>
  );
};

export default RolesAndPermissions;
