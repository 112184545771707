import {
  Avatar,
  Flex,
  useColorModeValue,
  Text,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import { ReportedUserDetailsType } from "../../types/common";
import { request } from "utils/api";
import React from "react";
import { useParams } from "react-router-dom";

const ReportedDetails = () => {
  const { id } = useParams();
  const cardBg = useColorModeValue("secondaryGray.300", "navy.900");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { data, isLoading } = useQuery({
    queryKey: ["reported-details", id],
    queryFn: async () => {
      const response = await request<ReportedUserDetailsType>(
        `/user_reports/${id}/reported/details`
      );
      return response;
    },
  });
  const reportedDetails = data?.success ? data?.data : null;
  return (
    <Card
      py="50px"
      minH="35vh"
      flexDirection="column"
      w="100%"
      bg={cardBg}
      px="0px"
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" h="100%" w="100%">
          <Spinner size="xl" color={textColor} />
        </Flex>
      ) : (
        <>
          <Flex
            gap="10px"
            flexDir="column"
            alignItems="center"
            textAlign="center"
          >
            <Avatar
              color="white"
              src={reportedDetails?.reportedDetails?.reported?.pp?.public_link}
              name={reportedDetails?.reportedDetails?.reported?.name}
              bg="#11047A"
              size="2xl"
              w="90px"
              h="90px"
            />
            <Text
              textAlign="center"
              textColor={textColor}
              fontWeight="light"
              flexShrink={0}
              fontSize="25px"
            >
              {reportedDetails?.reportedDetails?.reported?.name}
            </Text>
          </Flex>
          <Flex
            gap="10px"
            flexDir="column"
            alignItems="center"
            textAlign="center"
          >
            <Text
              textAlign="center"
              textColor={textColor}
              fontWeight="light"
              flexShrink={0}
              fontSize="25px"
            >
              {reportedDetails?.reportedDetails?.reported?.type}
            </Text>
          </Flex>
          <Flex
            justifyContent="space-evenly"
            alignItems="center"
            h="100%"
            pt="35px"
            px="20px"
            gap="40px"
            flexWrap="wrap"
          >
            <Box>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                reports created
              </Text>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                {reportedDetails?.reportsCount
                  ? reportedDetails?.reportsCount
                  : 0}
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                Most frequent reason
              </Text>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                {reportedDetails?.topReasons?.reason
                  ? reportedDetails?.topReasons?.reason
                  : "----"}
                {" ("}
                {reportedDetails?.topReasons?.total
                  ? reportedDetails?.topReasons?.total
                  : 0}
                {")"}
              </Text>
            </Box>
            <Box>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                got reported
              </Text>
              <Text
                textAlign="center"
                textColor={textColor}
                fontWeight="light"
                flexShrink={0}
                fontSize="20px"
              >
                {reportedDetails?.gotReportedCount
                  ? reportedDetails?.gotReportedCount
                  : 0}
              </Text>
            </Box>
          </Flex>
        </>
      )}
    </Card>
  );
};

export default ReportedDetails;
