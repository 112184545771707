import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
} from "recharts";
import { pieChartOptions } from "../../src/utils/constants";
import { Flex, Spinner, Text, Box, Select } from "@chakra-ui/react";
import PieChart from "components/charts/PieChart";
import { useQuery } from "@tanstack/react-query";
import { useContextState } from "utils/hocs/AppContext";
import Card from "components/card/Card";
import { request } from "utils/api";
import {
  RequestPercentageType,
  RequestTypeStats,
  UserStats,
} from "types/common";
import { RequestType } from "utils/types";
type Period = "month" | "week";
export default function Dashboard() {
  const { user } = useContextState();
  const [requestTypeId, setRequestTypeId] = useState<number | undefined>(
    undefined
  );
  const [period, setPeriod] = useState<Period>("month");
  const { data: requestPercentageData, isLoading: isRequestPercentageLoading } =
    useQuery({
      queryKey: ["requests_percentage"],
      queryFn: async () => {
        const response = await request<RequestPercentageType>(
          "/dashboard/requests/percentage"
        );
        return response;
      },
    });
  const requestPercentageStats: RequestPercentageType =
    requestPercentageData?.success ? requestPercentageData.data : null;
  const { data: requestStats, isLoading: isRequestStatsLoading } = useQuery({
    queryKey: ["requests_stats"],
    queryFn: async () => {
      const response = await request<RequestTypeStats>("/dashboard/requests");
      return response;
    },
  });
  const requestStatsWeekly: [] = requestStats?.success
    ? requestStats?.data?.weekly
    : [];
  const requestStatsMonthly: [] = requestStats?.success
    ? requestStats?.data?.monthly
    : [];
  const { data: requestTypeStats, isLoading: isRequestTypeStatsLoading } =
    useQuery({
      queryKey: ["requests_type_stats"],
      queryFn: async () => {
        const response = await request<RequestTypeStats>(
          "/dashboard/requests_type"
        );
        return response;
      },
    });
  const requestTypeStatsWeekly: [] = requestTypeStats?.success
    ? requestTypeStats?.data?.weekly
    : [];
  const requestTypeStatsMonthly: [] = requestTypeStats?.success
    ? requestTypeStats?.data?.monthly
    : [];
  const {
    data: singleRequestTypeStats,
    isLoading: isSingleRequestTypeStatsLoading,
  } = useQuery({
    queryKey: ["single_requests_type_stats", requestTypeId],
    queryFn: async () => {
      const response = await request<RequestTypeStats>(
        `/dashboard/requests_type/${requestTypeId}`
      );
      return response;
    },
    enabled: requestTypeId != undefined,
  });
  const requestSingleTypeStatsWeekly: [] = singleRequestTypeStats?.success
    ? singleRequestTypeStats?.data?.weekly
    : [];
  const requestSingleTypeStatsMonthly: [] = singleRequestTypeStats?.success
    ? singleRequestTypeStats?.data?.monthly
    : [];
  const { data: userStats, isLoading } = useQuery({
    queryKey: ["user_stats"],
    queryFn: async () => {
      const response = await request<UserStats>("/dashboard/users");
      return response;
    },
  });
  const userStatsWeekly: [] = userStats?.success ? userStats?.data.weekly : [];
  const userStatsMonthly: [] = userStats?.success
    ? userStats?.data.monthly
    : [];
  const { data: requestTypeData } = useQuery({
    queryKey: ["request_type"],
    queryFn: async () => {
      const response = await request<{ items: RequestType[] }>(
        "/request_types"
      );
      const firtsRequestType = response?.success
        ? response?.data?.items[0]?.id
        : undefined;
      setRequestTypeId(firtsRequestType);
      return response;
    },
  });
  const requestTypes = requestTypeData?.success
    ? requestTypeData?.data?.items || []
    : [];
  return (
    <Flex width="100%" height="100%" flexDir="column" gap={10}>
      <Card h="fit-content" w="100%" pl={10} py={5} fontWeight="black">
        Hello 👋 welcome back {user.name}
      </Card>
      <Flex gap={10} flexDir={{ base: "column", md: "row" }} w="100%">
        <Card justifyContent="center" alignItems="center">
          <Flex w="100%" justifyContent="center" alignItems="center" pb={5}>
            <Text textAlign="center" fontWeight="black" mr={5}>
              Users Created in the last
            </Text>
            <Select
              w="30%"
              value={period}
              onChange={(e) => setPeriod(e.target.value as Period)}
            >
              <option value="month">12 months</option>
              <option value="weeks">10 weeks</option>
            </Select>
          </Flex>
          {!isLoading ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={500}
                height={300}
                data={period === "month" ? userStatsMonthly : userStatsWeekly}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip labelStyle={{ color: "black" }} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Spinner size="xl" />
          )}
        </Card>
        <Card justifyContent="center" alignItems="center">
          <Flex w="100%" justifyContent="center" alignItems="center" pb={5}>
            <Text textAlign="center" fontWeight="black" mr={5}>
              Missions Created in the last{" "}
              {period === "month" ? "12 months" : "10 weeks"}
            </Text>
          </Flex>
          {!isRequestStatsLoading ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={500}
                height={300}
                data={
                  period === "month" ? requestStatsMonthly : requestStatsWeekly
                }
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip labelStyle={{ color: "black" }} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="requests"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Spinner size="xl" />
          )}
        </Card>
      </Flex>
      <Flex gap={10} flexDir={{ base: "column", md: "row" }} w="100%">
        <Card justifyContent="center" alignItems="center">
          <Flex w="100%" justifyContent="center" alignItems="center" pb={5}>
            <Text textAlign="center" fontWeight="black" mr={5}>
              Top 5 Mission Types Created in the last{" "}
              {period === "month" ? "12 months" : "10 weeks"}
            </Text>
          </Flex>
          {!isRequestTypeStatsLoading ? (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={
                  period === "month"
                    ? requestTypeStatsMonthly
                    : requestTypeStatsWeekly
                }
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis />
                <Tooltip labelStyle={{ color: "black" }} />
                <Legend />
                <Bar
                  dataKey="requests"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Spinner size="xl" />
          )}
        </Card>
        <Card justifyContent="center" alignItems="center">
          <Flex w="100%" justifyContent="center" alignItems="center" pb={5}>
            <Text textAlign="center" fontWeight="black" pr={4}>
              Missions Created of type
            </Text>
            <Select
              w="25%"
              value={requestTypeId}
              onChange={(e) => setRequestTypeId(parseInt(e.target.value))}
            >
              {requestTypes.length > 0 &&
                requestTypes.map((itm) => (
                  <option key={itm.id} value={itm.id}>
                    {itm.label}
                  </option>
                ))}
            </Select>
            <Text fontWeight="black" px={4}>
              in the last {period === "month" ? "12 months" : "10 weeks"}
            </Text>
          </Flex>
          {!isSingleRequestTypeStatsLoading ? (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={500}
                height={300}
                data={
                  period === "month"
                    ? requestSingleTypeStatsMonthly
                    : requestSingleTypeStatsWeekly
                }
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip labelStyle={{ color: "black" }} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="requests"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Spinner size="xl" />
          )}
        </Card>
      </Flex>
      <Flex gap={10} flexDir={{ base: "column", md: "row" }} w="100%">
        <Card justifyContent="center" alignItems="center">
          <Text fontWeight="black" pr={4} pb={4}>
            Number (%) of completed and canceled missions
          </Text>
          {!isRequestPercentageLoading ? (
            <ResponsiveContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              width="100%"
              height="100%"
            >
              <PieChart
                chartData={[
                  requestPercentageStats?.accepted_percentage ?? 0,
                  requestPercentageStats?.canceled_percentage ?? 0,
                ]}
                chartOptions={pieChartOptions}
              />
            </ResponsiveContainer>
          ) : (
            <Spinner size="xl" />
          )}
          <Flex justifyContent="center" alignItems="center" w="100%" pt={5}>
            <Box bg="#4318FF" h={6} w={6} />
            <Text pl={2}>Accepted Missions</Text>
            <Box ml={10} bg="#6AD2FF" h={6} w={6} />{" "}
            <Text pl={2}>Canceled Missions</Text>
          </Flex>
        </Card>
        <Card bg="transparent"></Card>
      </Flex>
    </Flex>
  );
}
