// Chakra imports
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import ColumnsTable from "components/tables/default";
import { useMemo, useState } from "react";
import { request } from "utils/api";
import { Page } from "utils/types";
import RadioCard from "components/radioCard/RadioCard";
import { PlusSquareIcon } from "@chakra-ui/icons";
import PageForm from "./components/PageForm";
import { langOptions, pageTypesLabels } from "utils/constants";

const columnHelper = createColumnHelper<Page>();
const findPage = (pages: { [key: string]: Page[] }, id: number) => {
  for (const pagesArray of Object.values(pages)) {
    const found = pagesArray.find((page) => page.id == id);
    if (found) return found;
  }

  return null;
};

export default function PagesList() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editing, setEditing] = useState<Page>(null);
  const onCloseModal = () => {
    setEditing(null);
    onClose();
  };

  const {
    getRootProps,
    getRadioProps,
    value: lang,
  } = useRadioGroup({
    name: "lang",
    defaultValue: "en",
  });
  const group = getRootProps();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages", lang],
    queryFn: async () => {
      return await request<{ [key in Page["type"]]: Page[] } | null>(
        `/pages?lang=${lang}`
      );
    },
  });
  const pagesGrps = data?.success ? data.data || undefined : undefined;

  const columns = useMemo(
    () => [
      columnHelper.accessor("title", {
        id: "title",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            TITLE
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              noOfLines={1}
            >
              {info.getValue()}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor("sub_title", {
        id: "sub_title",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            SUB TITLE
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              noOfLines={1}
            >
              {info.getValue() || "--"}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor("type", {
        id: "type",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            TYPE
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700" noOfLines={1}>
            {pageTypesLabels[info.getValue()]}
          </Text>
        ),
      }),
    ],
    [textColor]
  );

  return (
    <Flex direction="column" flex={1}>
      <Flex justifyContent="space-between" mb={5}>
        <HStack {...group}>
          {Object.entries(langOptions).map(([key, value]) => {
            const radio = getRadioProps({ value: key });
            return (
              <RadioCard key={key} {...radio}>
                {value}
              </RadioCard>
            );
          })}
        </HStack>
        <Button
          leftIcon={<PlusSquareIcon />}
          minH="100%"
          px={10}
          py={3}
          variant="brand"
          borderRadius="md"
          onClick={onOpen}
        >
          Add
        </Button>
      </Flex>
      {isLoading ? (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex flexWrap="wrap" gap={4}>
          {pagesGrps
            ? (Object.entries(pagesGrps) as [Page["type"], Page[]][]).map(
                ([key, pages]) => (
                  <ColumnsTable
                    key={key}
                    w="auto"
                    flex={1}
                    minW="48%"
                    columns={columns}
                    tableData={pages}
                    header={pageTypesLabels[key] || key}
                    onRowClick={(id) => {
                      const page = findPage(pagesGrps, id);
                      setEditing(page);
                      onOpen();
                    }}
                  />
                )
              )
            : null}
        </Flex>
      )}
      {!isOpen ? null : (
        <Modal isOpen onClose={onCloseModal} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{`${editing ? "Edit" : "Add"}`} a page</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PageForm
                data={editing}
                onClose={onCloseModal}
                onSuccess={() => {
                  refetch();
                  onCloseModal();
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}
