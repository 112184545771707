import { useContextState } from "utils/hocs/AppContext";
import { useCallback } from "react";

export const usePermission = () => {
  const { user } = useContextState();

  const isAllowed = useCallback(
    (moduleName: string, action?: string): boolean => {
      if (!user || !user.roles) {
        return false;
      }
      for (const role of user.roles) {
        if (
          role.permissions &&
          role.permissions.some(
            (permission) => permission.name === `${moduleName}.${action}`
          )
        ) {
          return true;
        }
      }
      return false;
    },
    [user]
  );

  return isAllowed;
};
