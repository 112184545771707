// Chakra imports
import { Portal, Box, useDisclosure, Flex } from "@chakra-ui/react";
// Layout components
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import routes from "routes";

interface LayoutProps {
  withOutlet?: boolean;
  children?: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ withOutlet = false, children }) => {
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                secondary={false}
                fixed={fixed}
              />
            </Box>
          </Portal>

          <Flex
            mx="auto"
            px={{ base: "20px", md: "30px" }}
            pe="20px"
            minH="100vh"
            pt={{ base: "170px", md: "80px", xl: "130px" }}
            pb="20px"
          >
            {withOutlet ? <Outlet /> : children}
          </Flex>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
};

export default Layout;
