import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { request } from "utils/api";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useMemo, useState } from "react";
import { useDebouncedValue } from "common/hooks/useDebouncedValue";
import Pagination from "../components/pagination/Pagination";
import { ReactComponent as NotFoundIcon } from "../assets/icons/not-found.svg";
import styled from "@emotion/styled";
import { PURCHASE_STATUS } from "utils/constants";
import ColumnsTable from "components/tables/default";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginatedData, Purchase } from "utils/types";
import PurchaseDetails from "./components/PurchaseDetails";

const StyleNotFoundIcon = styled(NotFoundIcon)`
  path {
    fill: ${(props) => props.fill || "#fff"};
  }
`;
const columnHelper = createColumnHelper<Purchase>();

export default function Purchases() {
  const [viewing, setViewing] = useState<Purchase>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const rowHoverBg = useColorModeValue("secondaryGray.300", "navy.900");
  const toast = useToast();

  const delayedHelper = useDebouncedValue(
    searchParams.get("helper_name") || ""
  );
  const updateQueryParams = (key: string, value: string): void => {
    setSearchParams((prevParams) => {
      const obj = Object.fromEntries(prevParams);
      obj[key] = value;
      return obj;
    });
  };
  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "purchases",
      currentPage,
      delayedHelper,
      searchParams.get("type"),
      searchParams.get("status"),
      perPage,
    ],
    queryFn: async () => {
      const response = await request<PaginatedData<Purchase>>(
        `/purchases?page=${currentPage}&perPage=${perPage}&issuer=${delayedHelper}
        &status=${encodeURIComponent(
          searchParams.get("status") || PURCHASE_STATUS.PENDING
        )}`
      );
      return response;
    },
  });
  const paginatedData = data?.success ? data?.data : null;
  const purchases = data?.success ? data?.data?.data : [];

  const columns = useMemo(
    () => [
      columnHelper.accessor("user.name", {
        id: "user.name",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            HELPER NAME
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              noOfLines={1}
            >
              {info.getValue()}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor("product.name", {
        id: "product.name",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            PRODUCT NAME
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              noOfLines={1}
            >
              {info.getValue()}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor("option", {
        id: "option",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            OPTION
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              noOfLines={1}
            >
              {info.getValue() || "--"}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor("status", {
        id: "status",
        header: () => (
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{ sm: "10px", lg: "12px" }}
            color="gray.400"
          >
            STATUS
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700" noOfLines={1}>
            {info.getValue()}
          </Text>
        ),
      }),
    ],
    [textColor]
  );

  return (
    <>
      <Card
        flexDirection="column"
        h="fit-content"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mb="20px" gap="40px" align="center">
          <SearchBar
            value={searchParams.get("helper_name") || ""}
            placeholder="Issuer name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              updateQueryParams("helper_name", e.target.value);
              setCurrentPage(1);
            }}
            flex={1}
            borderRadius="30px"
          />
          <Select
            bg={rowHoverBg}
            borderRadius="30px"
            _focus={{}}
            value={searchParams.get("status") || PURCHASE_STATUS.PENDING}
            onChange={(e): void => {
              updateQueryParams("status", e.target.value);
              setCurrentPage(1);
            }}
            placeholder="Status"
            flex={1}
            name="status"
            id="status"
          >
            {Object.keys(PURCHASE_STATUS).map((status) => {
              return (
                <option key={status} value={status}>
                  {status}
                </option>
              );
            })}
          </Select>
        </Flex>
        <Box minH="50vh">
          <ColumnsTable
            w="auto"
            flex={1}
            minW="48%"
            columns={columns}
            tableData={purchases}
            onRowClick={(id) => {
              const page = purchases.find((page) => page.id == id);
              setViewing(page);
            }}
          />
          {!isLoading && purchases.length == 0 ? (
            <Flex justifyContent="center" w="100%" h="100%">
              <Box maxW="300px" w="100%">
                <StyleNotFoundIcon fill={textColor} />
              </Box>
            </Flex>
          ) : null}
        </Box>
        <Pagination
          to={paginatedData?.to}
          perPage={perPage}
          setPerPage={setPerPage}
          from={paginatedData?.from}
          total={paginatedData?.last_page}
          current={currentPage}
          goToPage={setCurrentPage}
        />
      </Card>
      {viewing != undefined ? (
        <Modal
          isOpen
          onClose={() => setViewing(undefined)}
          isCentered
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Store purchase details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <PurchaseDetails
                data={viewing}
                mb="20px"
                onSuccess={async () => {
                  await refetch();
                  setViewing(undefined);

                  toast({
                    position: "top",
                    description: "Success !",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  );
}
