// Chakra imports
import { Button, Flex, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { request } from "utils/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { RequestType } from "utils/types";
import InputField from "components/fields/InputField";
import "react-quill/dist/quill.snow.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { mixed, number, object, string } from "yup";
import FileInput from "components/fields/FileInput";

interface RequestTypesFormProps {
  data?: RequestType;
  onClose: () => void;
  onSuccess: () => void;
}

const schema = object({
  label: string().required(),
  color_scheme: string().required(),
  reward_amount: number()
    .typeError("Should be a number (ex: 14.99)")
    .positive()
    .required("Champ obligatoire"),
  icon: mixed().required("Champ obligatoire"),
}).required();

interface RequestTypesFormFields {
  label: string;
  color_scheme: string;
  reward_amount: number;
  icon: File | { name: string };
}

const RequestTypesForm = ({
  data,
  onClose,
  onSuccess,
}: RequestTypesFormProps) => {
  const toast = useToast();
  const { mutateAsync: createRequestType, isPending } = useMutation({
    mutationFn: (formData: Partial<RequestTypesFormFields>) => {
      const body = new FormData();
      if (formData.icon instanceof File) {
        body.append("icon", formData.icon, formData.icon.name);
      }
      body.append("label", formData.label);
      body.append("reward_amount", formData.reward_amount.toString());
      body.append("color_scheme", formData.color_scheme);
      return request<RequestType>(
        data?.id ? `/request_types/${data.id}` : "/request_types",
        {
          headers: {},
          method: "POST",
          body,
        }
      );
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm<Partial<RequestTypesFormFields>>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: !data
      ? { reward_amount: 1, color_scheme: "#8500FA" }
      : {
          label: data.label,
          reward_amount: data.reward_amount,
          icon: {
            name: data.icon_uri.split("/")[data.icon_uri.split("/").length - 1],
          },
          color_scheme: data.color_scheme,
        },
  });
  const onSubmit: SubmitHandler<Partial<RequestTypesFormFields>> = async (
    data
  ) => {
    const response = await createRequestType(data);

    if (response.success) {
      toast({
        id: "request-type-create",
        status: "success",
        duration: 3000,
        description: "Successfully added the request type",
        position: "top",
      });
      onSuccess();
    } else {
      Object.entries(response.data || {}).forEach(([key, value]) => {
        setError(
          key as "icon" | "label" | "reward_amount",
          { message: value[0] },
          { shouldFocus: true }
        );
      });
      toast({
        id: "request-type-create",
        duration: 3000,
        status: "error",
        description: "Something went wrong",
        position: "top",
      });
    }
  };

  const onDelete = async () => {
    if (!data?.id) return;

    const response = await request(`/request_types/${data.id}`, {
      method: "DELETE",
    });

    if (response.success) {
      toast({
        id: "request-type-deleted",
        status: "success",
        duration: 3000,
        description: "Successfully deleted the request type",
        position: "top",
      });
      onSuccess();
    } else {
      toast({
        id: "request-type-deleted",
        duration: 3000,
        status: "error",
        description: "Something went wrong",
        position: "top",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="10px">
        <InputField
          id="label"
          label="Label *"
          placeholder="Label *"
          {...register("label", { required: true })}
          mb={5}
          borderRadius="md"
          error={errors.label?.message}
        />
        <InputField
          id="color_scheme"
          label="Color *"
          placeholder="Reward *"
          {...register("color_scheme", { required: true })}
          mb={5}
          type="color"
          borderRadius="md"
          error={errors.color_scheme?.message}
          px={0}
        />
        {/* <InputField
          id="reward_amount"
          label="Reward *"
          placeholder="Reward *"
          {...register("reward_amount", { required: true })}
          mb={5}
          borderRadius="md"
          error={errors.reward_amount?.message}
        /> */}
      </Flex>

      <FileInput
        label="Icon *"
        name="icon"
        fileName={watch("icon")?.name}
        setFile={(file: File): void => {
          setValue("icon", file);
        }}
        error={errors.icon?.message}
      />

      <Flex justifyContent="end" mt={5}>
        <Button variant="warning" mr="auto" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="solid" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="brand" isLoading={isPending} type="submit">
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default RequestTypesForm;
