import React from "react";
import {
  Flex,
  Text,
  Link,
  FormControl,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import styled from "@emotion/styled";
import { BsCloud } from "react-icons/bs";

const UploadIconContainer = styled.div`
  svg {
    width: 32px;
    height: auto;
  }
`;

interface FileInputProps {
  label: string;
  setFile: (file: File) => void;
  isInvalid: boolean;
  fileName: string;
  error: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FileInput: React.FC<any> = ({
  label,
  setFile,
  error,
  fileName,
  ...rest
}: FileInputProps): React.ReactElement => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => {
      setFile(files[0]);
    },
  });

  return (
    <FormControl isInvalid={!!error} flexDirection="column" flex={1} {...rest}>
      {label && (
        <Text
          fontSize="sm"
          color={textColorPrimary}
          mb="6px"
          ms="12px"
          fontWeight="bold"
        >
          {label}
        </Text>
      )}
      <Flex
        px="12px"
        align="center"
        justify="center"
        bg={isDragActive ? "#ebe5f3" : "#f3f3f3"}
        color="blackAlpha.400"
        height="40px"
        borderRadius="4px"
        border={error ? "2px solid" : "none"}
        borderColor="red.500"
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        {fileName === null || fileName === undefined ? (
          <>
            <UploadIconContainer>
              <BsCloud height="22px" />
            </UploadIconContainer>
            <Text fontSize="10px" color="#AAAAAA" ml="20px">
              <strong>Drag & drop</strong> your files to assets, or{"  "}
              <Link
                color="#3b86ff"
                href="#"
                fontWeight="bold"
                borderBottom="solid"
                borderBottomWidth="1.5px"
                borderBottomColor="#3b86ff"
                _hover={{ color: "#3b86ff" }}
              >
                browse
              </Link>
            </Text>
          </>
        ) : (
          <Text fontSize="xs" color="#0F1920" width="100%">
            {fileName}
          </Text>
        )}
      </Flex>
      <FormErrorMessage fontSize="xs" mt="2px">
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FileInput;
