// Chakra imports
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { request } from "utils/api";
import { RequestType } from "utils/types";
import { PlusSquareIcon } from "@chakra-ui/icons";
import RequestTypesForm from "./components/RequestTypesForm";
import Card from "components/card/Card";

export default function RequestTypesList() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editing, setEditing] = useState<RequestType>(null);
  const onCloseModal = () => {
    setEditing(null);
    onClose();
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages"],
    queryFn: async () => {
      return await request<{ items: RequestType[] }>("/request_types");
    },
  });
  const requestTypes = data?.success ? data.data.items || [] : [];

  return (
    <Flex direction="column" flex={1}>
      <Button
        leftIcon={<PlusSquareIcon />}
        px={10}
        py={3}
        mb={5}
        ml="auto"
        variant="brand"
        borderRadius="md"
        onClick={onOpen}
      >
        Add
      </Button>
      {isLoading ? (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex flexWrap="wrap" justifyContent="space-around" gap={4}>
          {requestTypes.map((itm) => (
            <Card
              key={itm.id}
              flexDirection="column"
              w="24%"
              cursor="pointer"
              position="relative"
              onClick={() => {
                setEditing(itm);
                onOpen();
              }}
            >
              <Flex
                position="absolute"
                w="20px"
                h="20px"
                borderRadius="50%"
                bgColor={itm.color_scheme}
              />
              <Flex
                w="100px"
                h="100px"
                bgColor="white"
                borderRadius="50%"
                marginInline="auto"
                mb="20px"
                p={6}
                justifyContent="center"
                alignItems="center"
              >
                <Image src={itm.icon_uri} w="100%" h="100%" />
              </Flex>
              <Text fontSize="2xl" textAlign="center">
                {itm.label}
              </Text>
              <Text fontSize="md" textAlign="center">
                {`Gives ${itm.reward_amount} Token(s)`}
              </Text>
            </Card>
          ))}
        </Flex>
      )}
      {!isOpen ? null : (
        <Modal isOpen onClose={onCloseModal} isCentered size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {`${editing ? "Edit" : "Add"}`} a request type
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RequestTypesForm
                data={editing}
                onClose={onCloseModal}
                onSuccess={() => {
                  refetch();
                  onCloseModal();
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}
