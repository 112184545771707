import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ContextProvider } from "utils/hocs/AppContext";
import Auth from "auth";
import ErrorBoundary from "components/ErrorBoundary";
import PrivateRouteWrapper from "utils/hocs/ProtectedRoute";
import Dashboard from "dashboard";
import Reports from "reports";
import ReportDetails from "reports/details";
import PagesList from "pages";
import { setLocale } from "yup";
import RequestTypesList from "requestTypes";
import ProductsList from "products";
import Purchases from "purchases";
import Users from "Users";

const queryClient = new QueryClient();

const container = document.getElementById("root");
const root = createRoot(container);

setLocale({
  mixed: {
    required: "Required field",
    oneOf: "Must be one of the provided options",
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "auth",
        element: <Auth />,
      },
      {
        path: "/",
        element: <PrivateRouteWrapper />,
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard" />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "reports",
            children: [
              {
                index: true,
                element: <Reports />,
              },
              {
                path: ":id",
                element: <ReportDetails />,
              },
            ],
          },
          {
            path: "pages",
            children: [
              {
                index: true,
                element: <PagesList />,
              },
            ],
          },
          {
            path: "request-types",
            children: [
              {
                index: true,
                element: <RequestTypesList />,
              },
            ],
          },
          {
            path: "products",
            children: [
              {
                index: true,
                element: <ProductsList />,
              },
            ],
          },
          {
            path: "purchases",
            children: [
              {
                index: true,
                element: <Purchases />,
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <Users />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <RouterProvider router={router} />
        </ContextProvider>
      </QueryClientProvider>
    </React.StrictMode>
  </ChakraProvider>
);
