import {
  Text,
  Avatar,
  Flex,
  useColorModeValue,
  Image,
  FlexProps,
  chakra,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useState } from "react";
import { request } from "utils/api";
import { Purchase } from "utils/types";

interface PurchaseDetailsProps extends FlexProps {
  data: Purchase;
  onSuccess: () => Promise<void>;
}

const PurchaseDetails: React.FC<PurchaseDetailsProps> = ({
  data,
  onSuccess,
  ...rest
}) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const textColorBrand = useColorModeValue("brand.500", "white");
  const { mutate: onHandle } = useMutation({
    mutationFn: (props: { id: number; isApproved?: boolean }) => {
      setIsLoading(true);
      return request(
        `/purchases/${props.id}/${props.isApproved ? "approve" : "reject"}`,
        {
          method: "PATCH",
          headers: { "Content-type": "application/json" },
        }
      );
    },
    onSuccess: async (response) => {
      if (response.success) {
        await onSuccess();
      } else {
        toast({
          position: "top",
          description: "An error has occurred...",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    },
    mutationKey: ["purchase_approval"],
  });

  return (
    <Flex direction="column" {...rest}>
      <Flex alignItems="center">
        <Text fontSize="xl" color={textColorBrand} fontWeight="bold">
          {data.user.name}
        </Text>
        <Avatar src={data.user.pp?.public_link} w="40px" h="40px" mx="10px" />
        <Text fontSize="xl" color={textColorBrand}>
          purchased{" "}
          <chakra.span fontWeight="bold">{data.product.name}</chakra.span>
          {` (x${data.amount})${
            data.option ? ` (option: ${data.option})` : ""
          } on ${format(new Date(data.created_at), "dd/MM/yyyy")}`}
        </Text>
      </Flex>
      <Image
        src={data.product.icon_uri}
        borderRadius="xl"
        w="100%"
        maxW="250px"
        maxH="250px"
        mx="auto"
        my="20px"
      />
      <Flex gap="20px" w="60%" minW="200px" mx="auto">
        <Button
          fontSize="sm"
          variant="warning"
          fontWeight="500"
          w="100%"
          h="50"
          mb="24px"
          onClick={() => onHandle({ id: data.id, isApproved: false })}
          isLoading={isLoading}
        >
          Reject
        </Button>
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="100%"
          h="50"
          mb="24px"
          onClick={() => onHandle({ id: data.id, isApproved: true })}
          isLoading={isLoading}
        >
          Approve
        </Button>
      </Flex>
    </Flex>
  );
};

export default PurchaseDetails;
