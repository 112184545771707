// Chakra imports
import { Button, Flex, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { request } from "utils/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { Product } from "utils/types";
import InputField from "components/fields/InputField";
import "react-quill/dist/quill.snow.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { mixed, number, object, string } from "yup";
import FileInput from "components/fields/FileInput";

interface ProductsFormProps {
  data?: Product;
  onClose: () => void;
  onSuccess: () => void;
}

const schema = object({
  name: string().required("Champ obligatoire"),
  attributes: string(),
  price: number()
    .typeError("Should be a number (ex: 14.99)")
    .positive()
    .required("Champ obligatoire"),
  stock: number()
    .typeError("Should be a number (ex: 20)")
    .positive()
    .required("Champ obligatoire"),
  icon: mixed().required("Champ obligatoire"),
}).required();

interface ProductsFormFields {
  name: string;
  price: number;
  attributes: string;
  stock: number;
  icon: File | { name: string };
}

const ProductsForm = ({ data, onClose, onSuccess }: ProductsFormProps) => {
  const toast = useToast();
  const { mutateAsync: createUpdateProduct, isPending } = useMutation({
    mutationFn: (formData: Partial<ProductsFormFields>) => {
      const body = new FormData();
      if (formData.icon instanceof File) {
        body.append("icon", formData.icon, formData.icon.name);
      }
      body.append("name", formData.name);
      body.append("price", formData.price.toString());
      body.append("attributes", formData.attributes.toString());
      body.append("stock", formData.stock.toString());
      return request<Product>(data?.id ? `/products/${data.id}` : "/products", {
        headers: {},
        method: "POST",
        body,
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm<Partial<ProductsFormFields>>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: !data
      ? undefined
      : {
          name: data.name,
          price: data.price,
          attributes: data.attributes,
          stock: data.stock,
          icon: {
            name: data.icon_uri.split("/")[data.icon_uri.split("/").length - 1],
          },
        },
  });
  const onSubmit: SubmitHandler<Partial<ProductsFormFields>> = async (data) => {
    const response = await createUpdateProduct(data);

    if (response.success) {
      toast({
        id: "page-create",
        status: "success",
        duration: 3000,
        description: "Successfully added the page",
        position: "top",
      });
      onSuccess();
    } else {
      Object.entries(response.data || {}).forEach(([key, value]) => {
        setError(
          key as "icon" | "name" | "price" | "attributes",
          { message: value[0] },
          { shouldFocus: true }
        );
      });
      toast({
        id: "page-create",
        duration: 3000,
        status: "error",
        description: "Something went wrong",
        position: "top",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap="10px">
        <InputField
          flex={3}
          id="name"
          label="Name *"
          placeholder="Name *"
          {...register("name", { required: true })}
          mb={5}
          borderRadius="md"
          formControllProps={{ flex: 3 }}
          error={errors.name?.message}
        />
        <InputField
          flex={1}
          id="price"
          textAlign="end"
          label="Price *"
          placeholder="Price *"
          {...register("price", { required: true })}
          formControllProps={{ flex: 1 }}
          mb={5}
          borderRadius="md"
          error={errors.price?.message}
        />
      </Flex>
      <Flex gap="10px">
        <InputField
          flex={1}
          id="attributes"
          label="Attributes *"
          placeholder="Attributes (separated with semicolon) *"
          {...register("attributes")}
          formControllProps={{ flex: 1 }}
          mb={5}
          borderRadius="md"
          error={errors.attributes?.message}
        />
        <InputField
          flex={1}
          id="stock"
          textAlign="end"
          label="Stock *"
          placeholder="Stock *"
          {...register("stock", { required: true })}
          formControllProps={{ flex: 1 }}
          mb={5}
          borderRadius="md"
          error={errors.stock?.message}
        />
      </Flex>

      <FileInput
        label="Icon *"
        name="icon"
        fileName={watch("icon")?.name}
        setFile={(file: File): void => {
          setValue("icon", file);
        }}
        error={errors.icon?.message}
      />

      <Flex justifyContent="end" mt={5}>
        <Button variant="solid" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="brand" isLoading={isPending} type="submit">
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default ProductsForm;
