import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
import React from "react";

interface DeleteUserConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  mutate: ({ type }: { type: string }) => void;
}
const DeleteUserConfirmation: React.FC<DeleteUserConfirmationProps> = ({
  isOpen,
  onClose,
  mutate,
}): React.ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure? You can't undo this action afterwards.
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            bg="red"
            textColor="white"
            onClick={(): void => mutate({ type: "DELETE" })}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteUserConfirmation;
