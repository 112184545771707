import { APIResponse } from "./types";

const baseUrl: string = process.env.REACT_APP_API_URL;

async function fetcher<T>(
  input: string,
  init?: RequestInit
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<APIResponse<T>> {
  const token = (
    JSON.parse(localStorage.getItem("user")) || { access_token: null }
  ).access_token;
  init = {
    ...init,
    headers: {
      ...((init || {}).headers || {}),
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
  const res = await fetch(`${baseUrl}${input}`, init);
  if (res.status === 401 || (res.status === 403 && !input.startsWith("auth"))) {
    localStorage.removeItem("user");
    window.location.replace("/");
  }
  // if (res.status >= 500 && res.status <= 511) {
  // 	throw new Error('API ERROR')
  // }
  return res.json();
}
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const request = async <T>(
  url: string,
  params?: RequestInit
): Promise<APIResponse<T>> => {
  const paramsOrDefault = {
    method: "GET",
    headers: { "Content-type": "application/json" },
    ...params,
  };
  try {
    return await fetcher<T>(url, paramsOrDefault);
  } catch (error) {
    return {
      success: false,
      code: 500,
      error,
      data: null,
    };
  }
};

export { fetcher, request };
