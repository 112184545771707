// Chakra imports
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Flex,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { request } from "utils/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { Page } from "utils/types";
import InputField from "components/fields/InputField";
import { langOptions, pageTypes, pageTypesLabels } from "utils/constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import SelectField from "components/fields/SelectField";
import styled from "@emotion/styled";

interface PageFormProps {
  data?: Page;
  onClose: () => void;
  onSuccess: () => void;
}

const StyledFormControl = styled(FormControl)`
  .ql-toolbar,
  .ql-picker {
    color: ${(props) => props.color};
  }
  .ql-stroke {
    stroke: ${(props) => props.color};
  }
  .ql-fill {
    fill: ${(props) => props.color};
  }
  .ql-toolbar svg {
    stroke: ${(props) => props.color};
  }
  .ql-container .ql-editor {
    min-height: 200px;
  }
`;

const schema = object({
  title: string().required(),
  user_type: string()
    .oneOf(["all", "helpee", "helper"], "Must be one of the provided options")
    .required(),
  sub_title: string(),
  type: string()
    .oneOf(
      [
        "commitments",
        "legal_info",
        "about_us",
        "about_the_applications",
        "payments",
      ],
      "Must be one of the provided options"
    )
    .required(),
  lang: string()
    .oneOf(
      ["en", "fr", "es", "ar", "it"],
      "Must be one of the provided options"
    )
    .required(),
  html: string().required(),
}).required();

const PageForm = ({ data, onClose, onSuccess }: PageFormProps) => {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const toast = useToast();
  const { mutateAsync: createPage, isPending } = useMutation({
    mutationFn: (formData: Partial<Page>) => {
      return request<Page>(data?.id ? `/pages/${data.id}` : "/pages", {
        headers: { "Content-Type": "application/json" },
        method: data?.id ? "PATCH" : "POST",
        body: JSON.stringify(formData),
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Partial<Page>>({
    resolver: yupResolver(schema),
    defaultValues: !data
      ? { user_type: "all" }
      : {
          lang: data.lang,
          type: data.type,
          title: data.title,
          sub_title: data.sub_title,
          user_type: data.user_type,
          html: data.html,
        },
  });
  const onSubmit: SubmitHandler<Partial<Page>> = async (data) => {
    const response = await createPage(data);

    if (response.success) {
      toast({
        id: "page-create",
        status: "success",
        duration: 3000,
        description: "Successfully added the page",
        position: "top",
      });
      onSuccess();
    } else {
      toast({
        id: "page-create",
        duration: 3000,
        status: "error",
        description: "Something went wrong",
        position: "top",
      });
    }
  };

  const onDelete = async () => {
    if (!data?.id) return;

    const response = await request(`/pages/${data.id}`, {
      method: "DELETE",
    });

    if (response.success) {
      toast({
        id: "page-deleted",
        status: "success",
        duration: 3000,
        description: "Successfully deleted the page",
        position: "top",
      });
      onSuccess();
    } else {
      toast({
        id: "page-deleted",
        duration: 3000,
        status: "error",
        description: "Something went wrong",
        position: "top",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={5}>
        <SelectField
          label="Language *"
          id="lang"
          placeholder="Language *"
          {...register("lang")}
          mb={5}
          _focus={{}}
          error={errors.lang?.message}
        >
          {Object.entries(langOptions).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </SelectField>
        <SelectField
          label="Type *"
          id="type"
          placeholder="Type *"
          {...register("type")}
          mb={5}
          _focus={{}}
          error={errors.type?.message}
        >
          {pageTypes.map((type) => (
            <option key={type} value={type}>
              {pageTypesLabels[type]}
            </option>
          ))}
        </SelectField>
        <SelectField
          label="User Type *"
          id="user-type"
          placeholder="User Type *"
          {...register("user_type")}
          mb={5}
          _focus={{}}
          error={errors.user_type?.message}
        >
          <option value="all">All</option>
          <option value="helpee">Helpee</option>
          <option value="helper">Helper</option>
        </SelectField>
      </Flex>

      <InputField
        id="title"
        label="Title *"
        placeholder="Title *"
        {...register("title", { required: true })}
        mb={5}
        borderRadius="md"
        error={errors.title?.message}
      />
      <InputField
        id="sub_title"
        label="Sub Title"
        borderRadius="md"
        placeholder="Sub Title"
        {...register("sub_title")}
        error={errors.sub_title?.message}
      />

      <StyledFormControl
        as={Flex}
        direction="column"
        isInvalid={!!errors.html?.message}
        color={textColorPrimary}
      >
        <FormLabel
          display="flex"
          ms="10px"
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          Content
        </FormLabel>
        <ReactQuill
          theme="snow"
          value={watch("html")}
          onChange={(val) => setValue("html", val)}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              [
                "bold",
                "italic",
                "underline",
                "strike",
                { color: [] },
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" },
              ],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link"],
              ["clean"],
            ],
          }}
        />
        {!errors.html?.message ? null : (
          <FormErrorMessage>{errors.html?.message}</FormErrorMessage>
        )}
      </StyledFormControl>

      <Flex justifyContent="end" mt={5}>
        <Button variant="warning" mr="auto" onClick={onDelete}>
          Delete
        </Button>
        <Button variant="solid" mr={3} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="brand" isLoading={isPending} type="submit">
          Submit
        </Button>
      </Flex>
    </form>
  );
};

export default PageForm;
