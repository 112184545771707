// Chakra imports
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { request } from "utils/api";
import { Product } from "utils/types";
import { PlusSquareIcon } from "@chakra-ui/icons";
import ProductsForm from "./components/ProductsForm";
import Card from "components/card/Card";

export default function ProductsList() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editing, setEditing] = useState<Product>(null);
  const onCloseModal = () => {
    setEditing(null);
    onClose();
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages"],
    queryFn: async () => {
      return await request<{ items: Product[] }>("/products");
    },
  });
  const products = data?.success ? data.data.items || [] : [];

  return (
    <Flex direction="column" flex={1}>
      <Button
        leftIcon={<PlusSquareIcon />}
        px={10}
        py={3}
        mb={5}
        ml="auto"
        variant="brand"
        borderRadius="md"
        onClick={onOpen}
      >
        Add
      </Button>
      {isLoading ? (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex flexWrap="wrap" justifyContent="space-around" gap={4}>
          {products.map((itm) => (
            <Card
              key={itm.id}
              flexDirection="column"
              w="24%"
              cursor="pointer"
              onClick={() => {
                setEditing(itm);
                onOpen();
              }}
            >
              <Image
                src={itm.icon_uri}
                maxW="80%"
                maxH="150px"
                marginInline="auto"
                mb="10px"
              />
              <Text fontSize="2xl" textAlign="center">
                {itm.name}
              </Text>
              <Text fontSize="md" textAlign="center">
                {itm.price} Token(s)
              </Text>
            </Card>
          ))}
        </Flex>
      )}
      {!isOpen ? null : (
        <Modal isOpen onClose={onCloseModal} isCentered size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{`${editing ? "Edit" : "Add"}`} a Product</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ProductsForm
                data={editing}
                onClose={onCloseModal}
                onSuccess={() => {
                  refetch();
                  onCloseModal();
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}
