import React, { useReducer, useContext } from "react";
import { UserInterface } from "utils/types";

export type PermissionType = {
  id: number;
  name: string;
};

export type GroupsType = {
  id: number;
  name: string;
  permissions?: Array<number>;
};

type State = {
  user: UserInterface | null;
  sideMenuIsCollapsed: boolean;
  fullMenuMode: boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Actions = { type: string; payload?: any };
export type Dispatch = (action: Actions) => void;
type ContextProviderProps = { children: React.ReactNode };

const AppContext = React.createContext<State | null>(null);
const AppDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function appReducer(state: State, action: Actions): State {
  switch (action.type) {
    case "TOGGLE_SIDEBAR_COLLAPSE":
      return {
        ...state,
        sideMenuIsCollapsed: action.payload,
      };
    case "TOGGLE_FULLMENU_MODE":
      return {
        ...state,
        fullMenuMode: action.payload,
      };
    case "ADD_USER":
      return {
        ...state,
        user: action.payload as UserInterface,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    default:
      throw new Error("Action not found");
  }
}

function ContextProvider({
  children,
}: ContextProviderProps): React.ReactElement {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const sideMenuIsCollapsed = false;
  const fullMenuMode = true;
  const initialState: State = { user, sideMenuIsCollapsed, fullMenuMode };
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

function useContextState(): State {
  const context = useContext(AppContext);
  if (context === null) {
    throw new Error("ContextProvider is not accessible");
  }

  return context;
}

function useContextDispatch(): Dispatch {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("ContextProvider is not accessible");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export { ContextProvider, useContextState, useContextDispatch };
