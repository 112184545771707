// Chakra imports
import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  UsersReport,
  UserReport,
  ReportsType,
  ReportType,
} from "../types/common";
import { useNavigate } from "react-router-dom";
import { request } from "utils/api";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { format } from "date-fns";
import { useState } from "react";
import { useDebouncedValue } from "common/hooks/useDebouncedValue";
import Pagination from "../components/pagination/Pagination";
import { ReactComponent as NotFoundIcon } from "../assets/icons/not-found.svg";
import styled from "@emotion/styled";
import { REPORT_STATUS } from "utils/constants";

const StyleNotFoundIcon = styled(NotFoundIcon)`
  path {
    fill: ${(props) => props.fill || "#fff"};
  }
`;

export default function Reports() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const rowHoverBg = useColorModeValue("secondaryGray.300", "navy.900");
  const delayedSearchIssuer = useDebouncedValue(
    searchParams.get("issuer_name") || ""
  );
  const delayedSearchReported = useDebouncedValue(
    searchParams.get("reported_name") || ""
  );
  const updateQueryParams = (key: string, value: string): void => {
    setSearchParams((prevParams) => {
      const obj = Object.fromEntries(prevParams);
      obj[key] = value;
      return obj;
    });
  };
  const { data, isLoading } = useQuery({
    queryKey: [
      "reports",
      currentPage,
      delayedSearchReported,
      delayedSearchIssuer,
      searchParams.get("type"),
      searchParams.get("status"),
      perPage,
    ],
    queryFn: async () => {
      const response = await request<UsersReport>(
        `/user_reports?page=${currentPage}&perPage=${perPage}&issuer=${delayedSearchIssuer}
        &reported=${delayedSearchReported}
        &type=${encodeURIComponent(searchParams.get("type") || "")}
        &status=${encodeURIComponent(
          searchParams.get("status") || REPORT_STATUS.PENDING
        )}`
      );
      return response;
    },
  });

  const reports: UserReport[] = data?.success ? data?.data?.data : [];
  const PaginationData: UsersReport = data?.success ? data?.data : null;
  const { data: reportTypeData } = useQuery({
    queryKey: ["report-type"],
    queryFn: async () => {
      const response = await request<ReportsType>("/report_types/children");
      return response;
    },
  });
  const ReportsTypes: ReportType[] = reportTypeData?.success
    ? reportTypeData?.data?.values
    : [];

  return (
    <Card
      flexDirection="column"
      h="fit-content"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="20px" gap="40px" align="center">
        <SearchBar
          value={searchParams.get("issuer_name") || ""}
          placeholder="Issuer name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            updateQueryParams("issuer_name", e.target.value);
            setCurrentPage(1);
          }}
          flex={1}
          borderRadius="30px"
        />
        <SearchBar
          value={searchParams.get("reported_name") || ""}
          placeholder="Reported name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            updateQueryParams("reported_name", e.target.value);
            setCurrentPage(1);
          }}
          flex={1}
          borderRadius="30px"
        />
        <Select
          bg={rowHoverBg}
          borderRadius="30px"
          _focus={{}}
          value={searchParams.get("type") || ""}
          onChange={(e): void => {
            updateQueryParams("type", e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Report type"
          flex={1}
          name="cars"
          id="cars"
        >
          {ReportsTypes?.map((itm, index) => {
            return (
              <optgroup key={index} label={itm.label}>
                {itm.children?.map((childrenItm, childIndex) => {
                  return (
                    <option key={childIndex} value={childrenItm.label}>
                      {childrenItm.label}
                    </option>
                  );
                })}
              </optgroup>
            );
          })}
        </Select>
        <Select
          bg={rowHoverBg}
          borderRadius="30px"
          _focus={{}}
          value={searchParams.get("status") || REPORT_STATUS.PENDING}
          onChange={(e): void => {
            updateQueryParams("status", e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Status"
          flex={1}
          name="status"
          id="status"
        >
          {Object.keys(REPORT_STATUS).map((status) => {
            return (
              <option key={status} value={status}>
                {status}
              </option>
            );
          })}
        </Select>
      </Flex>
      <Box minH="50vh">
        <Table variant="simple" color={textColor} mb="24px" mt="12px">
          <Thead>
            <Tr>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  ISSUER NAME
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  REPORTED NAME
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize="12px"
                  minW="130px"
                  color={textColor}
                >
                  REPORT TYPE
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize="12px"
                  minW="130px"
                  color={textColor}
                >
                  REASON
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  DATE
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody h="100%">
            {isLoading ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <Tr key={index}>
                    <Td colSpan={5}>
                      <Skeleton height="35px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : reports.length != 0 ? (
              reports.map((itm, index) => {
                return (
                  <Tr
                    key={index}
                    _hover={{ bg: rowHoverBg }}
                    cursor="pointer"
                    onClick={() => navigate(`/reports/${itm.id}`)}
                  >
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.issuer?.name ? itm.issuer?.name : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.reported?.name ? itm.reported?.name : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.report_type?.label ? itm.report_type?.label : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.reason ? itm.reason : "----"}
                    </Td>
                    <Td fontSize="14px" borderColor="transparent">
                      {itm.created_at
                        ? format(new Date(itm.created_at * 1000), "dd-MM-yyyy")
                        : "----"}
                    </Td>
                  </Tr>
                );
              })
            ) : null}
          </Tbody>
        </Table>
        {!isLoading && reports.length == 0 ? (
          <Flex justifyContent="center" w="100%" h="100%">
            <Box maxW="300px" w="100%">
              <StyleNotFoundIcon fill={textColor} />
            </Box>
          </Flex>
        ) : null}
      </Box>
      <Pagination
        to={PaginationData?.to}
        perPage={perPage}
        setPerPage={setPerPage}
        from={PaginationData?.from}
        total={PaginationData?.last_page}
        current={currentPage}
        goToPage={setCurrentPage}
      />
    </Card>
  );
}
