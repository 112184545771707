import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { BackOfficeRolesResponse, PostBackOfficeUser } from "types/common";
import { request } from "utils/api";

interface CreateNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  mutate: ({ type, data }: { type: string; data: PostBackOfficeUser }) => void;
}

const CreateNewUserModal: React.FC<CreateNewUserModalProps> = ({
  isOpen,
  onClose,
  mutate,
}): React.ReactElement => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { data: rolesData, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await request<BackOfficeRolesResponse>(`/roles`);
      return response;
    },
  });
  const roles = rolesData?.success ? rolesData.data.items : [];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      role: "",
      name: "",
      email: "",
      password: "",
      repeat_password: "",
    },
  });
  const onSubmit = (data: {
    role: string;
    name: string;
    email: string;
    password: string;
    repeat_password: string;
  }) => {
    mutate({ type: "POST", data: data });
    reset();
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.role}>
              <FormLabel htmlFor="role">Role</FormLabel>
              <Select
                id="role"
                {...register("role", {
                  required: "Please select a role",
                })}
              >
                <option value="">Select a role</option>
                {roles.map((itm, index) => (
                  <option key={index} value={itm.name}>
                    {itm.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                textColor={textColor}
                id="name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                textColor={textColor}
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter pt={10}>
          <Button variant="ghost" colorScheme="gray" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            type="submit"
            colorScheme="green"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Create User
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewUserModal;
