import { usePermission } from "common/hooks/usePermission";
import Layout from "components/Layout";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useContextState } from "./AppContext";

const PrivateRouteWrapper = (): React.ReactElement => {
  const { user } = useContextState();
  const location = useLocation();
  const moduleName = location.pathname.split("/")[1];
  const isAllowed = usePermission();
  const isModuleAuthorized = isAllowed(moduleName, "read");

  const isAuthorized = (): boolean => {
    const allowedPaths = ["dashboard"];
    if (allowedPaths.includes(moduleName) || !moduleName) {
      return true;
    }

    if (!isModuleAuthorized) {
      localStorage.removeItem("user");
      return false;
    }
    return isModuleAuthorized;
  };

  const isAuthenticated = (): boolean | React.ReactElement => {
    if (!user || !user.access_token) {
      localStorage.removeItem("user");
      return false;
    }

    return true;
  };
  return isAuthenticated() && isAuthorized() ? (
    <Layout withOutlet />
  ) : (
    <Navigate to="/auth" replace />
  );
};
export default PrivateRouteWrapper;
