// Chakra imports
import { Flex } from "@chakra-ui/react";
import Card from "components/card/Card";
import IssuerDetails from "reports/components/IssuerDetails";
import ReportedDetails from "reports/components/ReportedDetails";
import ReportedHistory from "reports/components/ReportedHistory";

export default function ReportDetails() {
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="2rem"
      gap="10px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        flexDir={{
          sm: "column",
          md: "row",
        }}
        gap="20px"
      >
        <ReportedDetails />
        <IssuerDetails />
      </Flex>
      <ReportedHistory />
    </Card>
  );
}
