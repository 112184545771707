import React from "react";

import {
  Button,
  Flex,
  IconButton,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { MdChevronLeft } from "react-icons/md";
import { LuChevronsRight } from "react-icons/lu";
import { concatParams, setParam } from "utils/pagination";

interface PaginationProps {
  total?: number;
  from?: number;
  to?: number;
  perPage?: number;
  setPerPage?: (numberInPage: number) => void;
  current?: number;
  goToPage?: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  current = 1,
  goToPage,
  setPerPage,
  to,
  perPage,
  from,
}: PaginationProps): React.ReactElement => {
  const bgColor = useColorModeValue("secondaryGray.900", "white");
  const textColor = useColorModeValue("white", "secondaryGray.900");
  const selectBg = useColorModeValue("secondaryGray.300", "navy.900");
  const navigate = useNavigate();
  const location = useLocation();
  const pages = [];
  const params = Array.from(new URLSearchParams(location.search).entries());
  goToPage =
    goToPage ||
    ((page: number): void => {
      navigate(
        `${location.pathname}${concatParams(
          setParam(params, "page", page.toString())
        )}`
      );
    });
  if (total && total > 1) {
    for (
      let i = Math.max(1, current - 2);
      i <= Math.min(total, current + 2);
      i++
    ) {
      pages.push(
        <Flex key={i} mr={3} align="center">
          <Button
            _hover={{}}
            borderRadius="full"
            bg={i == current ? bgColor : ""}
            color={i == current ? textColor : "gray.500"}
            boxShadow="0px 0px 10px 0px rgba(0,0,0,.1) "
            onClick={(): void => goToPage && goToPage(i)}
            _focus={{}}
          >
            {i}
          </Button>
        </Flex>
      );
    }
  } else {
    pages.push(
      <Button
        key={1}
        _hover={{}}
        borderRadius="full"
        bg={bgColor}
        color={textColor}
        mr={3}
        boxShadow="0px 0px 10px 0px rgba(0,0,0,.1) "
      >
        {current}
      </Button>
    );
  }
  return (
    <Flex justify="space-between" w="100%" p={10}>
      <Flex align="center">
        <Select
          defaultValue={10}
          bg={selectBg}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
            setPerPage(parseInt(e.target.value));
          }}
        >
          <option value="10">10 / Pages</option>
          <option value="20">20 / Pages</option>
          <option value="25">25 / Pages</option>
        </Select>
        <Text
          textColor={bgColor}
          fontWeight="light"
          flexShrink={0}
          fontSize="17px"
          pl="30px"
        >
          Showing {from ? from : 0} to {to ? to : 0} of {perPage} entries
        </Text>
      </Flex>
      <Flex align="center">
        <IconButton
          aria-label=""
          _hover={{}}
          mr={3}
          p={0}
          borderRadius="full"
          bg="white"
          isDisabled={current === 1}
          fontSize="2xl"
          color="gray.500"
          icon={<MdChevronLeft />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,.1) "
          onClick={(): void => goToPage && goToPage(current - 1)}
        />
        {pages}
        <IconButton
          aria-label=""
          _hover={{}}
          mr={3}
          p={0}
          isDisabled={current === total}
          borderRadius="full"
          bg="white"
          fontSize="2xl"
          color="gray.500"
          icon={<LuChevronsRight />}
          boxShadow="0px 0px 10px 0px rgba(0,0,0,.1) "
          onClick={(): void => goToPage && goToPage(current + 1)}
        />
      </Flex>
    </Flex>
  );
};

export default Pagination;
