import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { usePermission } from "common/hooks/usePermission";
import Card from "components/card/Card";
import React from "react";
import UsersList from "./UsersList";
import RolesAndPermissions from "./UsersRolesAndPermissions";

const Users: React.FC = (): React.ReactElement => {
  const bgColor = useColorModeValue("secondaryGray.300", "secondaryGray.900");
  const isAllowed = usePermission();
  return (
    <Card>
      <Tabs h="100%" variant="enclosed">
        <TabList>
          <Tab _selected={{ bgColor: bgColor }} _focus={{}} px={10}>
            Users
          </Tab>
          {isAllowed("users", "edit") && (
            <Tab _selected={{ bgColor: bgColor }} _focus={{}}>
              Roles and permissions
            </Tab>
          )}
        </TabList>
        <TabPanels h="100%">
          <TabPanel>
            <UsersList />
          </TabPanel>
          {isAllowed("users", "edit") && (
            <TabPanel h="100%">
              <RolesAndPermissions />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default Users;
