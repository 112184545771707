import React from "react";
import { useRouteError } from "react-router-dom";
import NotFound from "./NotFound";

function ErrorBoundary() {
  const error = useRouteError() as { status: number };
  console.error(error.status);
  if (error.status === 404) {
    return <NotFound />;
  }
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

export default ErrorBoundary;
