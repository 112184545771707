import React, { useEffect, useMemo, useState } from "react";
import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ModalCloseButton,
  Select,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { BackOfficeRolesResponse, PostBackOfficeUser } from "types/common";
import { request } from "utils/api";

interface DeleteUserConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  roleId: number;
  userId: string;
  mutate: ({ type, data }: { type: string; data: PostBackOfficeUser }) => void;
}

const EditUsersRoleModal: React.FC<DeleteUserConfirmationProps> = ({
  isOpen,
  onClose,
  roleId,
  mutate,
  userId,
}): React.ReactElement => {
  const [cooldown, setCooldown] = useState(0);
  const toast = useToast();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const { data: rolesData, isSuccess } = useQuery({
    queryKey: ["roles"],
    queryFn: async () => {
      const response = await request<BackOfficeRolesResponse>(`/roles`);
      return response;
    },
  });

  const roles = useMemo(
    () => (rolesData?.success ? rolesData.data.items : []),
    [rolesData]
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      role: roles.find((role) => role.id === roleId)?.name || "",
      name: "",
      email: "",
      password: "",
      repeat_password: "",
    },
  });

  useEffect(() => {
    setValue("role", roles.find((role) => role.id === roleId)?.name || "");
  }, [isSuccess, roles, roleId, setValue]);

  const handleResetPassword = async () => {
    const response = await request(`/office_users/${userId}/reset_password`, {
      headers: { "Content-Type": "application/json" },
      method: "PUT",
    });
    if (response.code === 0) {
      toast({
        id: "password-reset",
        status: "success",
        duration: 3000,
        description: "The email with password rest has been sent",
        position: "top",
        isClosable: true,
      });
      setCooldown(10);
    }
  };
  useEffect(() => {
    if (cooldown > 0) {
      const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [cooldown]);

  const onSubmit = (userData: PostBackOfficeUser) => {
    mutate({
      type: "PUT",
      data: {
        ...userData,
      },
    });
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.role}>
              <FormLabel htmlFor="role">Role</FormLabel>
              <Select
                id="role"
                {...register("role", {
                  required: "Please select a role",
                })}
              >
                <option value="">Select a role</option>
                {roles.map((itm, index) => (
                  <option key={index} value={itm.name}>
                    {itm.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                textColor={textColor}
                id="name"
                {...register("name", { required: "Name is required" })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                textColor={textColor}
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter pt={10}>
          <Button
            variant="ghost"
            colorScheme="gray"
            mr={3}
            px={4}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            colorScheme="yellow"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty}
          >
            Update
          </Button>
          <Button
            variant="solid"
            colorScheme="red"
            onClick={handleResetPassword}
            isDisabled={cooldown > 0}
            ml={3}
          >
            {cooldown > 0
              ? `Reset Password in (${cooldown})`
              : "Reset Password"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUsersRoleModal;
