import { Button, Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Flex
      flex={1}
      direction="column"
      h="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Heading>404 Not Found</Heading>
      <Button
        variant="brand"
        mt="1rem"
        p="1.5rem"
        onClick={() => navigate("/")}
      >
        Go Home
      </Button>
    </Flex>
  );
}

export default NotFound;
