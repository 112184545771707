import { Box, Button, RadioProps, useRadio } from "@chakra-ui/react";

const RadioCard: React.FC<RadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Button
        variant="brand"
        as={Box}
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        style={{ boxShadow: "none" }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Button>
    </Box>
  );
};

export default RadioCard;
