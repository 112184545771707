const setParam = (
  params: string[][],
  key: string,
  value: string
): string[][] => {
  const newParams = [...params.map((item) => [...item])];
  let updated = false;
  for (let i = 0; i < newParams.length; i++) {
    if (newParams[i][0] === key) {
      newParams[i][1] = value;
      updated = true;
      break;
    }
  }
  if (!updated) newParams.push([key, value]);
  return newParams;
};

const concatParams = (params: string[][]): string =>
  params.reduce(
    (cumule, item) =>
      item[1] == "" ? cumule : `${cumule}&${item[0]}=${item[1]}`,
    "?"
  );

export { setParam, concatParams };
