import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

interface CreateNewRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  mutate: (role: string) => void;
}

const CreateNewRoleModal: React.FC<CreateNewRoleModalProps> = ({
  isOpen,
  mutate,
  onClose,
}): React.ReactElement => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      role: "",
    },
  });

  const onSubmit = (data: { role: string }) => {
    mutate(data.role);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Role</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isInvalid={!!errors.role}>
              <Input
                textColor={textColor}
                id="email"
                {...register("role", {
                  required: "Please enter a role",
                })}
                placeholder="Enter role name"
              />
              <FormErrorMessage>
                {errors.role && errors.role.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter py={5}>
            <Button variant="ghost" colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="green" isLoading={isSubmitting}>
              Create Role
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewRoleModal;
