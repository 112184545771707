import {
  Flex,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Skeleton,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Card from "components/card/Card";
import React from "react";
import { useParams } from "react-router-dom";
import { reportedHistoryType } from "types/common";
import { request } from "utils/api";
import { format } from "date-fns";

const ReportedHistory = () => {
  const nestedCardBg = useColorModeValue("secondaryGray.300", "navy.900");
  const navigate = useNavigate();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const rowHoverBg = useColorModeValue("white", "navy.700");
  const { id } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["reported-history", id],
    queryFn: async () => {
      const response = await request<reportedHistoryType>(
        `/user_reports/${id}/reported/history`
      );
      return response;
    },
  });
  const reportedHistoryData = data?.success ? data?.data?.items : [];

  return (
    <Card flexDirection="column" w="100%" bg={nestedCardBg}>
      <Box minH="20vh">
        <Table variant="simple" color={textColor} mb="24px" mt="12px">
          <Thead>
            <Tr>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  ISSUER NAME
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  REPORTED NAME
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize="12px"
                  minW="130px"
                  color={textColor}
                >
                  REPORT TYPE
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  fontSize="12px"
                  minW="130px"
                  color={textColor}
                >
                  REASON
                </Flex>
              </Th>
              <Th colSpan={1} pe="10px" borderColor="black">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  minW="130px"
                  fontSize="12px"
                  color={textColor}
                >
                  DATE
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {isLoading ? (
              <>
                {[...Array(4)].map((_, index) => (
                  <Tr key={index}>
                    <Td colSpan={5}>
                      <Skeleton height="35px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {reportedHistoryData.map((itm, index) => {
                  return (
                    <Tr
                      key={index}
                      _hover={{ bg: rowHoverBg }}
                      cursor="pointer"
                      onClick={() => navigate(`/reports/${itm.id}`)}
                    >
                      <Td fontSize="14px" borderColor="transparent">
                        {itm.issuer?.name ? itm.issuer?.name : "----"}
                      </Td>
                      <Td fontSize="14px" borderColor="transparent">
                        {itm.reported?.name ? itm.reported?.name : "----"}
                      </Td>
                      <Td fontSize="14px" borderColor="transparent">
                        {itm.report_type?.label
                          ? itm.report_type?.label
                          : "----"}
                      </Td>
                      <Td fontSize="14px" borderColor="transparent">
                        {itm.reason ? itm.reason : "----"}
                      </Td>
                      <Td fontSize="14px" borderColor="transparent">
                        {itm.created_at
                          ? format(
                              new Date(itm.created_at * 1000),
                              "dd-MM-yyyy"
                            )
                          : "----"}
                      </Td>
                    </Tr>
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
};

export default ReportedHistory;
