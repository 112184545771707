// Chakra imports
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { forwardRef } from "react";
// Custom components

interface InputFieldProps extends InputProps {
  id: string;
  label: string;
  extra?: JSX.Element;
  formControllProps?: FormControlProps;
  error?: string;
}

export default forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const { id, label, extra, mb, error, formControllProps, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    return (
      <FormControl
        flexDirection="column"
        mb={mb ? mb : "30px"}
        isInvalid={!!error}
        {...formControllProps}
      >
        <FormLabel
          display="flex"
          {...(rest.textAlign === "end"
            ? { me: "10px", justifyContent: "end" }
            : { ms: "10px" })}
          htmlFor={id}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {label}
          {!extra ? null : (
            <Text fontSize="sm" fontWeight="400" ms="2px">
              {extra}
            </Text>
          )}
        </FormLabel>
        <Input
          {...rest}
          ref={ref}
          id={id}
          fontWeight="500"
          variant="outline"
          color={textColorPrimary}
          _focus={{}}
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          h="44px"
          maxH="44px"
        />
        {!error ? null : <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
