import { VscReport } from "react-icons/vsc";
import { Icon } from "@chakra-ui/react";
import { MdHome, MdInsertPageBreak } from "react-icons/md";
import { IoMdHelpBuoy } from "react-icons/io";
import { FaShoppingCart } from "react-icons/fa";
import { FaBasketShopping } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";

const routes = [
  {
    name: "Dashboard",
    layout: "/dashboard",
    path: "",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Reports",
    layout: "/reports",
    path: "",
    icon: <Icon as={VscReport} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Pages mobile",
    layout: "/pages",
    path: "",
    icon: (
      <Icon as={MdInsertPageBreak} width="20px" height="20px" color="inherit" />
    ),
  },
  {
    name: "Request Types",
    layout: "/request-types",
    path: "",
    icon: <Icon as={IoMdHelpBuoy} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Products",
    layout: "/products",
    path: "",
    icon: (
      <Icon as={FaShoppingCart} width="20px" height="20px" color="inherit" />
    ),
  },
  {
    name: "Purchases",
    layout: "/purchases",
    path: "",
    icon: (
      <Icon as={FaBasketShopping} width="20px" height="20px" color="inherit" />
    ),
  },
  {
    name: "Users",
    layout: "/users",
    path: "",
    icon: <Icon as={FaUsers} width="20px" height="20px" color="inherit" />,
  },
];

export default routes;
