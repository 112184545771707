// Chakra imports
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { forwardRef } from "react";
// Custom components

interface SelectFieldProps extends SelectProps {
  id: string;
  label: string;
  error?: string;
}

export default forwardRef<HTMLSelectElement, SelectFieldProps>(
  function SelectField(props, ref) {
    const { id, label, mb, error, children, ...rest } = props;
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

    return (
      <FormControl
        as={Flex}
        direction="column"
        mb={mb ? mb : "30px"}
        isInvalid={!!error}
      >
        <FormLabel
          display="flex"
          ms="10px"
          htmlFor={id}
          fontSize="sm"
          color={textColorPrimary}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {label}
        </FormLabel>
        <Select ref={ref} _focus={{}} {...rest}>
          {children}
        </Select>
        {!error ? null : <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
