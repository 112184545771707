// Chakra imports
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { ReactComponent as LogoLight } from "assets/icons/logo-light.svg";
import { ReactComponent as LogoDark } from "assets/icons/logo-dark.svg";
// import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  const logo = useColorModeValue(<LogoLight />, <LogoDark />);

  return (
    <Flex alignItems="center" flexDirection="column">
      <Box w="175px" my="25px">
        {logo}
      </Box>
      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
}

export default SidebarBrand;
